import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  EmployeeGrid, EngagementTab, CommonContainer,
  EngagementContainer, SplitEngagementSection, HeaderTitle, NewUserCard, ChallengesContainer,
  ImageContainer, NoData, BirthdayContainer, ThemeGrid, ButtonContainer, ThemeCard, DataThemeGrid,
  ProfileData, PointData, DataThemeGridNew, BirthdayContainerNew, NewCardContainer, NewRow1, NewTotalCount, NewCardDashboard, NewPointData,
  MoreMonthData, ConnectDevice, SeeAll, /*ContentBody,*/ NewConnectDevice, HeaderContainerNew, CommonContainerV2, NoDataV2, ButtonWrapper, CardWrapper, SeeAllButton, InitiativeButtonV2New, ViewDetailsBtn
} from "./styles";
import { commaSeperator, findMonth, convertDateInTimezone } from "../../utils/methods";
import { HeaderContainer } from "../HealthTab/styles";
import Image from '../Image';
import TopChallenge from "./TopChallenge";
import { ImgContainer, Row1, CardContainer, CardDashboard, CardDashboardNew, TotalCountNew, CardDashboardN } from "../CompanyDashBoardV2/styles";
// import BirthdayModal from './BirthdayModal';
import { isNull, isUndefined } from "lodash";
import Waiting from "../Waiting";
import Loading from "../Loading";
import { ImageUrl } from '../../utils/constants';
// const MonthPopUp = React.lazy(() => import('./MonthPopUp'));
import MonthPopUp from "./MonthPopUp";
import CompanyInitiative from './CompanyInitiative';
import SingleCompanyInitiative from './SingleCompanyInitiative';
const ThankYouPopup = React.lazy(() => import('./ThankYouPopup'));
import { addCompanyInitiatives, fetchCompanyInfo, updateCompanyInfo } from '../../redux/actions/companyDashboard';
import ShowAllCompanyInitiative from './CompanyInitiative/showAllInitiatives'
import LazyImage from '../common/LazyImage/LazyImage';
import { InvitationButtonWrapper, InvitationCardWrapper } from '../ChallengeDashboardV2/styles';
import CompanyInfoUpdate from '../CompanyDashBoardV2/companyInfoUpdate';
import { RowV1 } from '../Recognition/styles';
import { ProgressBar } from '../HealthTabV2/HealthTabUpdated/styles';
import HoverButton from '../common/HoverButton';
import DetailPopup from './CompanyInitiative/detailPopup';
import { DownAngle } from '../../components/SocialFeedsV2/styles';
import { DropdownButton, DropdownItem } from 'react-bootstrap';
import { fetchApi } from '../../utils/methods';
import { UpdateCompanyInitiatives } from '../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
import { getCompanyInitiatives, getTopWellnessInitiatives } from '../../redux/actions/companyDashboard';
import DeletePopup from '../EngagementSectionV2/DeletePopup';
class EngagementSectionV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initiativeLength: true,
      month: '',
      showBirthdayModal: false,
      seeMore: false,
      showRolesPopup: false,
      companyPopup: false,
      singleCompanyPopUpState: false,
      thankYouPopupState: false,
      selectedInitiative: {},
      value: "No Points",
      launchDate:new Date(),
      isUpcoming:0,
      selectedOption:0,
      isEdit:false,
      pointsArrow : false,
      step:1,
      calendarStatus : 0,
      isDeletePopup:false,
      // companyIdNew: this.props.companyId.id,
      featureNewData: [
        { key: "0 Points", Value: "0 Points", id: '6' },
        { key: "25 Points", Value: "25 Points", id: '1' },
        { key: "50 Points", Value: "50 Points", id: '2' },
        { key: "100 Points", Value: "100 Points", id: '3' },
        { key: "150 Points", Value: "150 Points", id: '4' },
        { key: "200 Points", Value: "200 Points", id: '5' },
        { key: "250 Points", Value: "250 Points", id: '7' },
        { key: "300 Points", Value: "300 Points", id: '8' },
        { key: "350 Points", Value: "350 Points", id: '9' },
        { key: "400 Points", Value: "400 Points", id: '10' },
        { key: "450 Points", Value: "450 Points", id: '11' },
        { key: "500 Points", Value: "500 Points", id: '12' },
        { key: "550 Points", Value: "550 Points", id: '13' },
        { key: "600 Points", Value: "600 Points", id: '14' },
        { key: "650 Points", Value: "650 Points", id: '15' },
        { key: "700 Points", Value: "700 Points", id: '16' },
        { key: "750 Points", Value: "750 Points", id: '17' },
        { key: "800 Points", Value: "800 Points", id: '18' },
        { key: "850 Points", Value: "850 Points", id: '19' },
        { key: "900 Points", Value: "900 Points", id: '20' },
        { key: "950 Points", Value: "950 Points", id: '21' },
        { key: "1000 Points", Value: "1000 Points", id: '22' }
      ],
      showAllInitiatives: false,
      showCompanyInfoPopup: false,
      seeAllEvents : false,
      seeAllChallenges:false,
      showDetailPopup:false,
      detailInitiative:null
    }
  }

  componentDidMount() {
    const {companyId, getCompanyInitiatives, getTopWellnessInitiatives} = this.props
    const d = new Date;
    const month = findMonth(d.getMonth());
    const currentYear = new Date().getFullYear();
    this.setState({
      month
    });
    this.props.fetchCompanyInfo(companyId);
    getCompanyInitiatives(this.props.companyId);
    getTopWellnessInitiatives(this.props.companyId,currentYear)
  }

  onNext = () => {
    this.setState({step:2})
  }
  onBack = () => {
    this.setState({step:1})
  }

  handleArrow = () => {
    this.setState({pointsArrow: !this.state.pointsArrow})
  }

  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e });
    const today = new Date();
    if (e === 1) {
      // .toISOString().split('T')[0]
      this.setState({launchDate: today});
    } else if(e === 2) {
      this.setState({launchDate: new Date(this.state.launchDate) || today});
      this.onChange()
    }
  };

  onChange = (value,event) => {
    event.preventDefault();
    this.setState({
      launchDate: value,
      // .toISOString().split('T')[0],
      isUpcoming:1,
      calendarStatus:0
    })
  }

  toggleCalendar = () => {
    this.setState({calendarStatus: 1 });
  }

  onChangeDropdown = (list) => {
    this.setState({ value: list.key, pointsArrow:!this.state.pointsArrow });
    // if (list.key === "0 Points") {
    //   this.setState({ value: "No Points" });
    // }
    // else {
    //   this.setState({ value: list.key });
    // }
  }

  singleCompanyPopUp = (obj) => {
    this.setState({
      companyPopup: false,
      singleCompanyPopUpState: true,
      selectedInitiative: obj
    });
  };

  updateInitiative = async()=>{
    // const AuthToken = localStorage.getItem('AUTH_TOKEN');

    const payload = {point_value:this.state.value,launch_date:moment(new Date(this.state.launchDate)).format("YYYY-MM-DD"),company_id:this.props.companyId,initiative_id:this.state.selectedInitiative.id,is_upcoming:this.state.isUpcoming,}

    try {
      const res = await fetchApi(`${UpdateCompanyInitiatives}`, "PUT",payload, {},this.successFunc);
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }
    
  }

  // To handle the edit initiative
  editInitiative = (item) => {
    this.setState({value:item.points,launchDate:item.launch_date,isUpcoming:item.is_upcoming,selectedOption:item.is_upcoming === 0 ? 1 : 2, isEdit:true})
    this.singleCompanyPopUp(item)
  }

  deleteInitiative = (item) => {
    this.setState({selectedInitiative:item,isDeletePopup:true})
  }

  closeDeletePopup = (status) => {
    this.setState({isDeletePopup:status})
  }

  successFunc = ()=>{
    if(this.state.isDeletePopup){
      const currentYear = new Date().getFullYear();
      this.props.getCompanyInitiatives(this.props.companyId);
      this.props.getTopWellnessInitiatives(this.props.companyId,currentYear)

    }else{
      this.setState({
        singleCompanyPopUpState: false,
        step:1,
        isEditId:null
      },()=>{      const currentYear = new Date().getFullYear();
        this.props.getCompanyInitiatives(this.props.companyId);
        this.props.getTopWellnessInitiatives(this.props.companyId,currentYear)});
      toast.success("Initiative Updated Successfully");

    }
  }

  userModal = () => {
    this.setState({
      showRolesPopup: false
    });
  };
  addMonthlyPopUp = () => {
    this.setState({
      showRolesPopup: !this.state.showRolesPopup
    });
  };

  companyModal = () => {
    this.setState({
      companyPopup: false
    });
  };
  companyPopUp = () => {
    this.props.history.push({pathname:`/company/initiative`, state:{showAll:false}})
    // this.setState({
    //   companyPopup: !this.state.companyPopup
    // });
  };

  singleCompanyModal = () => {
    this.setState({
      singleCompanyPopUpState: false, value: "No Points"
    });
  };

  // singleCompanyPopUp = (obj) => {
  //   this.setState({
  //     companyPopup: false,
  //     singleCompanyPopUpState: true,
  //     selectedInitiative: obj
  //   });
  // };

  // showCompanyInfoPopup = (id) =>{
  //   this.setState(() => ({
  //     showCompanyInfoPopup: true
  //   }), () => this.props.fetchCompanyInfo(id))
  // }

  // hideCompanyInfoPopup = () =>{
  //   this.setState ({
  //     showCompanyInfoPopup: false
  //   })
  // }

  updateInformationApi = (obj) =>{
    this.setState(() => ({
      showCompanyInfoPopup: false,
    }), () => this.props.updateCompanyInfo(obj))
  }


  addCompanyData = () => {
    const { addCompanyInitiatives, companyId } = this.props;
    const { selectedInitiative, value, launchDate, isUpcoming } = this.state;
    const year=new Date().getFullYear();
    let obj = {};
    obj['company_id'] = companyId
    obj['initiative_id'] = selectedInitiative.id
    obj['point_value'] = value;
    obj['launch_date'] = launchDate.toISOString().split('T')[0];
    obj['is_upcoming'] = isUpcoming;
    addCompanyInitiatives(obj, companyId, year);
  }

  // thankYouPopup = () => {
  //   this.setState({
  //     singleCompanyPopUpState: false,
  //     thankYouPopupState: true,
  //   });
  //   this.addCompanyData();
  // };

  thankYouPopup = () => {

    if(this.state.isEdit){
      this.updateInitiative();
      // this.addCompanyData();
    }else{
      this.setState({
        singleCompanyPopUpState: false,
        thankYouPopupState: true,
      });
      this.addCompanyData();

    }

  };

  thankYouCloseModal = () => {
    this.setState({
      thankYouPopupState: false,
      value: "No Points"
    });
  };

  showAllInitiatives = () => {
    // this.setState({
    //   showAllInitiatives: !this.state.showAllInitiatives
    // });
    this.props.history.push({pathname:`/company/initiative`, state:{showAll:true}})
  };

  showAllevents = () => {
    this.setState({seeAllEvents:!this.state.seeAllEvents})
  }
  showAllChallenges = () => {
    this.setState({seeAllChallenges:!this.state.seeAllChallenges})
  }

  showEngagementEmployeeDetail = (eligibleEmployee) => {
    if (isNull(eligibleEmployee['average_engagement']) || isUndefined(eligibleEmployee['average_engagement'])) {
      return null
    }
    const EngagementEmployeeData = [
      {
        title: "Users",
        titleNew: "Enrolled",
        value: "1",
        img: "/images/AdminEngagement/NewGroupIcon.png",
        count: this.props.employeeCount,
        totalCount: eligibleEmployee['eligible_employee'],
        status: false,
        color: "#85c0ea",
        backgroundColor:"#edf6fc",
        borderRadius: "11px",
        eligible_users: true,
        key:"users_enrolled"
      },
      {
        title: "Challenge",
        titleNew: "Participation",
        value: "2",
        img: "/images/AdminEngagement/NewGroupRed.png",
        count: `${this.props.challengesWellnessCountKey['challenge_count']}`,
        totalCount: `${this.props.challengesWellnessCountKey['challenge_total_count']}`,
        status: true,
        color: "#f1c977",
        backgroundColor:'#fdf7eb',
        key:"challenge_participation"
      },
      {
        title: "Wellness",
        titleNew: "Engagement",
        value: "3",
        img: "/images/AdminEngagement/NewGroupPink.png",
        count: `${this.props.challengesWellnessCountKey['wellness_count']}`,
        totalCount: `${this.props.challengesWellnessCountKey['wellness_total_count']}`,
        status: true,
        color: "#f4aaa9",
        backgroundColor:'#fef2f2',
        borderRadius: "11px",
        key:"wellness_engagement"
      },
      {
        title: "Average",
        titleNew: "Total Points",
        value: "4",
        img: "/images/AdminEngagement/NewGroupGreen.png",
        count: eligibleEmployee['average_points'],
        totalCount: "10,000",
        color: "#76ab78",
        backgroundColor:'#ebf3eb',
        points_available: true,
        key:"average_total_points"
      }
    ];
    return EngagementEmployeeData.map((details, index) => (
      <>
        {(!this.props.companyBranding?.company_branding?.show_challenge && details?.key === "challenge_participation" || !this.props.companyBranding?.company_branding?.show_education && details?.key === "wellness_engagement")? null : <CardContainer key={index}>
          <Row1 >
            <ImgContainer bgColor={details.bgColor}>
              <LazyImage src={ImageUrl + details.img} />
            </ImgContainer>
            <div className="textdata">
              <p style={{color:"#005c87"}}>{details.title}</p>
              <p style={{color:"#005c87"}}>{details.titleNew}</p>
            </div>
          </Row1>
          <RowV1>
            <div style={{margin:'19px 0px 0px 0px',width:'100%'}}>
              <ProgressBar background={details.backgroundColor} backgroundActive={details.color} width={index === 0 || index === 3 ? (details.count * 100/details.totalCount)  : details.count > 100 ? 100 : details.count}>
                <div className="progress"></div>
              </ProgressBar>
            </div>
          </RowV1>
          <Row1 paddingTop={'paddingTop'} font={"14px"} style={{display:"flex",justifyContent:'space-between',flexDirection:details.value === "4" || details.value === "1" ? "row" : "row-reverse"}}>
            <TotalCountNew bgcolor={details.color}>{commaSeperator(`${details.count}` || 0)}{index ===1 || index ===2 ? "%" :""} </TotalCountNew>
            <div className="totaldata" style={{color:details.value === "1" ? "#85c0ea" : details.value === "2" ? "#f1c977" : details.value === "3" ? "#f4aaa9" : "#76ab78"}}>{details.totalCount} <span style={{color:"#005c87"}}>{details.eligible_users ? "Purchased Seats" : ""}</span><span style={{color:"#005c87"}}>{details.title === "Challenge" ? "Users Participated" : details.title === "Wellness" ?  "Users Engaged" : ""}</span><span style={{color:"#005c87"}}>{details.points_available ? "Pts Available" : ""}</span></div>
          </Row1>
        </CardContainer>
        }
      </>
    ))
  };

  showCardData = () => {
    const CardTable = [
      {
        title: "Fitness Video Completed",
        img: "/images/AdminEngagement/RunGroup.svg",
        count: this.props.companyEducationDetailsKey['fitness_video_count'],
        text: "Users Watch the Fitness Video",
        color: "#f4aaa9"

      },
      {
        title: "Recipe’s Tried",
        img: "/images/AdminEngagement/FitnessVideoGroup.svg",
        count: this.props.companyEducationDetailsKey['recipe_watched_count'],
        text: "Users Cook the Nutrition Recipe",
        color: "#76ab78"


      }
    ];
    return CardTable.map((details, index) => (
      <NewCardContainer key={index} width={index % 2 === 1 ? '392px' : '367px'}>
        <NewRow1>
          <ImgContainer bgColor={details.bgColor}>
            <LazyImage src={ImageUrl + details.img} />
          </ImgContainer>
          <p style={{color:"#005c87"}}>{details.title}</p>
        </NewRow1>
        <NewRow1>
          <NewTotalCount bgcolor={details.color}>{index !== 2 ? commaSeperator(details.count || 0) : details.count}</NewTotalCount>
          <div className="newData" style={{color:"#005c87"}}>{details.text}</div>
        </NewRow1>
      </NewCardContainer>
    ))
  };

  showCardDataNew = () => {
    const CardTable = [
      {
        title: "Wellness Articles Read",
        img: "/images/AdminEngagement/QuizGroup.svg",
        count: this.props.companyEducationDetailsKey['article_read_count'],
        text: "Users Read an Article",
        color: "#f1c977"
      },
      {

        title: "Wellness Quizzes Taken",
        img: "/images/AdminEngagement/WellnessArticleGroup.svg",
        count: this.props.companyEducationDetailsKey['quiz_passed_count'],
        text: "Users Completed a Quiz",
        color: "#85c0ea"
      }
    ];
    return CardTable.map((details, index) => (
      <NewCardContainer key={index} width={index % 2 === 1 ? '392px' : '367px'}>
        <NewRow1>
          <ImgContainer bgColor={details.bgColor}>
            <LazyImage src={ImageUrl + details.img} />
          </ImgContainer>
          <p style={{color:"#005c87"}}>{details.title}</p>
        </NewRow1>
        <NewRow1>
          <NewTotalCount bgcolor={details.color}>{index !== 2 ? commaSeperator(details.count || 0) : details.count}</NewTotalCount>
          <div className="newData" style={{color:"#005c87"}}>{details.text}</div>
        </NewRow1>
      </NewCardContainer>
    ))
  };

  showCardSixData = () => {

    const CardTable = [
      {
        title: "Daily Tips Viewed",
        img: "/images/AdminEngagement/NewDaily_Tips_Icon.svg",
        count: this.props.companyEducationDetailsKey['daily_tips_count'],
        text: "Users Read an Article",
        color: "#af87c1",
        bgColor: "rgba(157, 135, 169, 0.15)"
      },
      {

        title: "Average Daily Tips Viewed",
        img: "/images/AdminEngagement/daily_AverageIcon.svg",
        count: this.props.companyEducationDetailsKey['daily_tips_average_count'],
        text: "Users Read an Article",
        color: "#ffbf73"
      }
    ];
    return CardTable.map((details, index) => (
      <NewCardContainer key={index} width={index % 2 === 1 ? '392px' : '367px'}>
        <NewRow1>
          <ImgContainer>
            <LazyImage src={ImageUrl + details.img} />
          </ImgContainer>
          <p style={{color:"#005c87"}}>{details.title}</p>
        </NewRow1>
        <NewRow1>
          <NewTotalCount bgcolor={details.color}>{index !== 2 ? commaSeperator(details.count || 0) : details.count}</NewTotalCount>
          <div className="newData" style={{color:"#005c87"}}>{details.text}</div>
        </NewRow1>
      </NewCardContainer>
    ))
  };

  challengesData = (topChallenges, color) => (
    <div style={{width:'398px'}}>
      {this.newRenderHeader("Company Challenges", color)}
      {this.state.seeAllChallenges ? 
        <InvitationCardWrapper style={{marginTop:"15px",boxShadow:"none",borderRadius:'6px 6px 0px 0px'}}>
          {topChallenges && topChallenges.length > 0 && topChallenges.map((data, index) => <div key={index}>
            <div style={{display:"flex", gap:'15px',alignItems:'center',cursor:'pointer'}} onClick={() => this.props.history.push(`/company/challenges/${data.id}`)}>
              <div style={{width:"150px", height:"92px", borderRadius:"6px"}}>
                <LazyImage src={ImageUrl +"/"+ data.image} style={{width:"150px", height:"92px", borderRadius:"6px"}}/>
              </div>
              <div>
                <p style={{color:"#005c87",fontSize:'16px', lineHeight:'20px', width:'208px', fontFamily:'rubik-medium'}}>{data.title}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px',marginBottom:'1px', fontFamily:'rubik'}}>Start Date : {moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px', fontFamily:'rubik'}}>End Date : {moment(convertDateInTimezone(data.end_date)._d).format('MMM DD, YYYY')}</p>
              </div>
            </div>
            <InvitationButtonWrapper marginBottom={topChallenges && topChallenges.length > 0}>
              <ViewDetailsBtn onClick={() => this.props.history.push(`/company/challenges/${data.id}`)}>View Details</ViewDetailsBtn>
            </InvitationButtonWrapper>
            {index < topChallenges.length - 1 && <div style={{ border: '1px solid #b3cfdb', margin:'25px 0px' }}></div>}
          </div>)}
        </InvitationCardWrapper>
        :
        <InvitationCardWrapper style={{marginTop:"15px",boxShadow:"none",borderRadius:'6px 6px 0px 0px'}}>
          {topChallenges && topChallenges.length > 0 && topChallenges.slice(0,2).map((data, index) => <div key={index}>
            <div style={{display:"flex", gap:'15px',alignItems:'center',cursor:'pointer'}} onClick={() => this.props.history.push(`/company/challenges/${data.id}`)}>
              <div style={{width:"150px", height:"92px", borderRadius:"6px"}}>
                <LazyImage src={ImageUrl +"/"+ data.image} style={{width:"150px", height:"92px", borderRadius:"6px"}}/>
              </div>
              <div>
                <p style={{color:"#005c87",fontSize:'16px', lineHeight:'20px', width:'208px', fontFamily:'rubik-medium'}}>{data.title}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px',marginBottom:'1px', fontFamily:'rubik'}}>Start Date : {moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px', fontFamily:'rubik'}}>End Date : {moment(convertDateInTimezone(data.end_date)._d).format('MMM DD, YYYY')}</p>
              </div>
            </div>
            <InvitationButtonWrapper marginBottom={topChallenges && topChallenges.length > 0}>
              <ViewDetailsBtn onClick={() => this.props.history.push(`/company/challenges/${data.id}`)}>View Details</ViewDetailsBtn>
            </InvitationButtonWrapper>
            {topChallenges.length === 1 || index < topChallenges.slice(0, 2).length - 1 &&
              <div style={{ border: '1px solid #b3cfdb', margin: '25px 0px' }}></div>}
          </div>)}
        </InvitationCardWrapper>}
      {topChallenges && topChallenges.length > 2 && <div style={{width:'398px',height:"22px",background:'#f2f7f9',display:'flex',justifyContent:'center',alignItems:"center",fontSize:'12px',color:'#005c87',cursor:"pointer"}} onClick={this.showAllChallenges}>{this.state.seeAllChallenges? "See Less":"See All"}</div>}
    </div>
  );


  eventData = (topEvents, color) => (
    <div style={{width:'398px'}}>
      {this.newRenderHeader("Company Events", color)}
      {this.state.seeAllEvents ? <InvitationCardWrapper style={{marginTop:"15px",boxShadow:"none",borderRadius:'6px 6px 0px 0px'}}>
        {topEvents && topEvents.length > 0 && topEvents.map((data, index) => { 
          const startDate = moment(convertDateInTimezone(data.start_date)._d);
          const endDate = moment(convertDateInTimezone(data.end_date)._d);
        
          const displayDateFormat = 'MMM DD, YYYY';
          const displayTimeFormat = 'h:mm A';
          return <div key={index}>
            <div style={{display:"flex", gap:'15px',alignItems:'center',cursor:'pointer'}} onClick={() => this.props.history.push(`/company/events/${data.id}`)}>
              <div style={{width:"150px", height:"92px", borderRadius:"6px"}}>
                <LazyImage src={ImageUrl +"/"+ data.image} style={{width:"150px", height:"92px", borderRadius:"6px"}}/>
              </div>
              <div>
                <p style={{color:"#005c87",fontSize:'16px', lineHeight:'20px', width:'208px', fontFamily:'rubik-medium'}}>{data.title}</p>
                {/* <p style={{color:"#669db7",fontSize:'14px', width:'208px',marginBottom:'1px', fontFamily:'rubik'}}>{moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px', fontFamily:'rubik'}}>{moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p> */}
                <p style={{ color: "#669db7", fontSize: '14px', width: '208px', marginBottom: '1px', fontFamily: 'rubik' }}>
                  {startDate.format(displayDateFormat)} | {endDate.format(displayDateFormat)}
                  <br />
                  {startDate.format(displayTimeFormat)} - {endDate.format(displayTimeFormat)} (IST)
                </p>
              </div>
            </div>
            <InvitationButtonWrapper marginBottom={topEvents && topEvents.length > 0}>
              <ViewDetailsBtn onClick={() => this.props.history.push(`/company/events/${data.id}`)}>View Details</ViewDetailsBtn>
            </InvitationButtonWrapper>
            {index < topEvents.length - 1 && <div style={{ border: '1px solid #b3cfdb', margin:'25px 0px' }}></div>}
          </div>})}
      </InvitationCardWrapper> : 
        <InvitationCardWrapper style={{marginTop:"15px",boxShadow:"none",borderRadius:'6px 6px 0px 0px'}}>
          {topEvents && topEvents.length > 0 && topEvents.slice(0,2).map((data, index) => { 
            const startDate = moment(convertDateInTimezone(data.start_date)._d);
            const endDate = moment(convertDateInTimezone(data.end_date)._d);
        
            const displayDateFormat = 'MMM DD, YYYY';
            const displayTimeFormat = 'h:mm A';
            return <div key={index}>
              <div style={{display:"flex", gap:'15px',alignItems:'center',cursor:'pointer'}} onClick={() => this.props.history.push(`/company/events/${data.id}`)}>
                <div style={{width:"150px", height:"92px", borderRadius:"6px"}}>
                  <LazyImage src={ImageUrl +"/"+ data.image} style={{width:"150px", height:"92px", borderRadius:"6px"}}/>
                </div>
                <div>
                  <p style={{color:"#005c87",fontSize:'16px', lineHeight:'20px', width:'208px', fontFamily:'rubik-medium'}}>{data.title}</p>
                  {/* <p style={{color:"#669db7",fontSize:'14px', width:'208px',marginBottom:'1px', fontFamily:'rubik'}}>{moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p>
                <p style={{color:"#669db7",fontSize:'14px', width:'208px', fontFamily:'rubik'}}>{moment(convertDateInTimezone(data.start_date)._d).format('MMM DD, YYYY')}</p> */}
                  <p style={{ color: "#669db7", fontSize: '14px', width: '208px', marginBottom: '1px', fontFamily: 'rubik' }}>
                    {startDate.format(displayDateFormat)} | {endDate.format(displayDateFormat)}
                    <br />
                    {startDate.format(displayTimeFormat)} - {endDate.format(displayTimeFormat)} (IST)
                  </p>
                </div>
              </div>
              <InvitationButtonWrapper marginBottom={topEvents && topEvents.length > 0}>
                <ViewDetailsBtn onClick={() => this.props.history.push(`/company/events/${data.id}`)}>View Details</ViewDetailsBtn>
              </InvitationButtonWrapper>
              {topEvents.length === 1 || index < topEvents.slice(0, 2).length - 1 &&
              <div style={{ border: '1px solid #b3cfdb', margin: '25px 0px' }}></div>}
            </div>})}
        </InvitationCardWrapper>}
      {topEvents && topEvents.length > 2 && <div style={{width:'398px',height:"22px",background:'#f2f7f9',display:'flex',justifyContent:'center',alignItems:"center",fontSize:'12px',color:'#005c87',cursor:"pointer"}} onClick={this.showAllevents}>{this.state.seeAllEvents ? "See Less":"See All"}</div>}
    </div>  
  );

  plusIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 0C8.44771 0 8 0.447716 8 1V8H1C0.447715 8 0 8.44772 0 9C0 9.55229 0.447716 10 1 10H8V17C8 17.5523 8.44771 18 9 18C9.55229 18 10 17.5523 10 17V10H17C17.5523 10 18 9.55228 18 9C18 8.44771 17.5523 8 17 8H10V1C10 0.447715 9.55229 0 9 0Z" fill="#005C87" />
    </svg>
  )

  initiativeRenderHeader = (title, bgColor, showRightSection, color) => {
    const { selectedYearInfo } = this.props;
    return <HeaderContainer setColor={bgColor} color={color} style={{height:"60px",borderBottom:"1px solid #b3cfdb",borderRadius:'6px 6px 0px 0px'}}>
      <div style={{marginLeft:"15px"}}>
        <HeaderTitle color={color} style={{color:color}}>{title}</HeaderTitle>
      </div>
      {this.props.companyInitiatives && this.props.companyInitiatives.length ? (<HoverButton
        title="Add Initiative"
        width="18px"
        height="18px"
        svgPath={this.plusIcon()}
        fillOpacity={1} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#598ea8"} inActiveColor={"white"}
        onClick={this.companyPopUp}
      />): null}
      {
        title === "COMPANY INITIATIVES" &&
        <div>
          <span><h5 className={'current-year'}>{selectedYearInfo}</h5></span>
        </div>
      }
      {
        showRightSection &&
        <div>
          <div className="addbutton" onClick={this.showPopUp}>+</div>
        </div>
      }
    </HeaderContainer>
  };



  renderHeader = (title, bgColor, showRightSection, color) => {
    const { selectedYearInfo } = this.props;
    return <HeaderContainer setColor={bgColor} color={color} style={{height:"60px",borderRadius:"6px 6px 0px 0px",borderBottom:title === "Upcoming Monthly Themes" ? "none" : "1px solid #b3cfdb"}}>
      <div style={{marginLeft:"15px",width:"100%"}}>
        <HeaderTitle color={color} style={{color:color,textAlign:title === "Upcoming Monthly Themes" ? "center":"left",width:"100%"}}>{title}</HeaderTitle>
      </div>
      {
        title === "COMPANY INITIATIVES" &&
        <div>
          <span><h5 className={'current-year'}>{selectedYearInfo}</h5></span>
        </div>
      }
      {
        showRightSection &&
        <div>
          <div className="addbutton" onClick={this.showPopUp}>+</div>
        </div>
      }
    </HeaderContainer>
  };


  newRenderHeader = (title, color) =>
    <HeaderContainer borderBottom={1} color={color} background="1">
      <div>
        <HeaderTitle color={color} style={{color:"#005c87"}}>{title}</HeaderTitle>
      </div>
    </HeaderContainer>;

  renderHeaderNew = (title, color) =>
    <HeaderContainerNew borderBottom={1} color={color}>
      <div>
        <HeaderTitle color={color}>{title}</HeaderTitle>
      </div>
    </HeaderContainerNew>;


  showWellnessInitiatives = (wellnessInitiativeData) => (
    <CardWrapper style={{position:"relative",height:"100%"}}>
      {wellnessInitiativeData && wellnessInitiativeData.length > 0 && wellnessInitiativeData.map((data, index) => (
        index<5 &&
        <NewUserCard width="24px" padding="9px 17px" key={index} borderTop={wellnessInitiativeData.length===(index+1)} style={{cursor:"pointer"}} /* onClick={()=>this.setState({showDetailPopup:true,detailInitiative:data})} */>
          <EmployeeGrid gridWidth="auto" onClick={()=>this.setState({showDetailPopup:true,detailInitiative:data})}>
            <LazyImage src={`${ImageUrl}/${data.initiative_image}`} />
          </EmployeeGrid>
          <EmployeeGrid gridWidth="63%" paddingLeft={1} onClick={()=>this.setState({showDetailPopup:true,detailInitiative:data})}>
            <div className="name" style={{color:"#005c87",fontFamily:'Rubik-Medium'}}>{data.initiative_name}</div>
            <NewPointData color={"#669db7"}>{data.is_upcoming ? "Upcoming" : "Active"} | +{data.points} pts</NewPointData>
          </EmployeeGrid>
          {data.is_upcoming ? /* <div onClick={() => this.setState({isEditId:index})} style={{width:'40px',height:'40px',marginLeft:'40px',background:"#f0f6f8",borderRadius:'6px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center'}}><img src={ImageUrl+"/threeDotIcon.svg"} alt="menu" /></div> */   <DownAngle width={1} marginL="0px" marginT="-25px">
            <DropdownButton id="buddy" title={<div style={{width:"40px",height:"40px",background:"rgb(240, 246, 248)",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"6px"}}><img src='/public/images/menuIcon.png' style={{width:"24px", height:"auto"}}/></div>}>
              <DropdownItem onClick={() => this.editInitiative(data)}>
                {"Edit"}
              </DropdownItem>
              <DropdownItem onClick={() => this.deleteInitiative(data)}>
                {"Delete"}
              </DropdownItem>
            </DropdownButton>
          </DownAngle>:<EmployeeGrid gridWidth="22%" textAlign={1} bgColor={"#f2f7f9"} employeeGrid radius={1} color="black" style={{minHeight:"35px",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <PointData><span className='percentage'>{Math.round(data.percentage)}%</span></PointData>
          </EmployeeGrid>}
        </NewUserCard>
      ))}
      {wellnessInitiativeData && (wellnessInitiativeData.length <= 4) &&
        <ButtonWrapper position="1" style={{position:"absolute",top:wellnessInitiativeData.length === 1 ? "170px" :'305px',paddingBottom:"0",flexDirection:'column',alignItems:'center',gap:'25px'}}>
          {wellnessInitiativeData.length === 1 && <p style={{color:"#005c87",fontFamily:'rubik',fontSize:"18px",textAlign:'center'}}>Start creating Company<br />
              Initiatives for your employees.<br />
              And get to know how much your<br />
              company employees are fit.</p>}
          <InitiativeButtonV2New onClick={this.companyPopUp}>
              Launch Initiatives
          </InitiativeButtonV2New>
        </ButtonWrapper>}
      {wellnessInitiativeData && wellnessInitiativeData.length >5 &&<SeeAllButton onClick={this.showAllInitiatives} style={{color:"#005c87",background:"#f2f7f9",height:'28px'}}>
        See All
      </SeeAllButton>}
    </CardWrapper>
  );

  closeDetailPopup=()=>{
    this.setState({showDetailPopup:false,detailInitiative:null})
    
  }

  showNewAddInitiatives = (wellnessInitiativeData, bgColor,color) => (
    (wellnessInitiativeData ?
      <div className="initiative-popup">
        {this.initiativeRenderHeader('Company Initiatives', bgColor, false,color)}
        <CommonContainerV2 style={{height:'390px',borderRadius:'6px'}}>
          {!isEmpty(wellnessInitiativeData) ? this.showWellnessInitiatives(wellnessInitiativeData) : <NoDataV2>
            <div className="data" style={{color:"#005c87"}}>
              Start creating Company<br />
              Initiatives for your employees.<br />
              And get to know how much your<br />
              company employees are fit.
            </div>
            <ButtonWrapper>
              <InitiativeButtonV2New onClick={this.companyPopUp}>
                  Create Initiatives
              </InitiativeButtonV2New>
            </ButtonWrapper>
          </NoDataV2>
          }
        </CommonContainerV2>
      </div> : '')
  );

  topChallengesDashboard = (TopChallengesData, bgColor) => {
    const { history } = this.props;
    if (isNull(TopChallengesData) || isUndefined(TopChallengesData)) {
      return (
        <div className="boxShadow">
          {this.renderHeader('YOUR ACTIVE CHALLENGE', bgColor, false)}
          <CommonContainer textAlign padding="30px">
            <ChallengesContainer>
              <Loading />
            </ChallengesContainer>
          </CommonContainer>
        </div>
      )
    }
    return (
      <div className="boxShadow">
        {this.renderHeader('YOUR ACTIVE CHALLENGE', bgColor, false)}
        {TopChallengesData.length > 0 ? TopChallengesData.map((challenge, index) =>
          <TopChallenge key={index} challenge={challenge} history={history} />) :
          <CommonContainer textAlign ><ChallengesContainer noFloat={1}>No top challenge</ChallengesContainer>
          </CommonContainer>}
      </div>
    );
  };

  showNewUser = (data) => (
    data.map((newUser, index) => (
      <NewUserCard key={index} borderTop={1} padding="10px 17px">
        <div className="employee-info">
          <EmployeeGrid gridWidth="auto">
            <ImageContainer>
              <LazyImage src={ImageUrl + "/" + newUser.profile_image} alt="no-image" />
            </ImageContainer>
          </EmployeeGrid>
          <EmployeeGrid gridWidth="auto" paddingLeft={1}>
            <ProfileData>
              <div style={{color:"#005c87"}}>{newUser.name}</div>
              <div className="dcls" style={{color:"#669db7"}}>{newUser.department}</div>
            </ProfileData>
          </EmployeeGrid>
        </div>
      </NewUserCard>
    ))
  );

  newUsersDashboard = (NewUserData, bgColor,color) => (
    (NewUserData ?
      <BirthdayContainer>
        <div className="boxShadow">
          {this.renderHeader('New Woliba Users In this Month', bgColor, false,color)}
          <CommonContainer float={1} padding="0" style={{ "padding": "5px 0px 0px 0px", "minHeight": "365px",borderRadius:"0px 0px 6px 6px"}}>
            {isEmpty(NewUserData) ? <NoData>No new Users</NoData> : this.showNewUser(NewUserData)}
          </CommonContainer>
        </div>
      </BirthdayContainer> : '')
  );
  hideDeviceModal = () => (
    this.setState({ showBirthdayModal: false })
  )
  showBirthDayModal = () => (
    this.setState({ showBirthdayModal: true })
  );


  getFormattedBirthday = (date) => {
    const formattedDate = [];
    const birthday = moment(date, "YYYY-MM-DD").format('Do');
    const month = moment(date, "YYYY-MM-DD").format('MMMM');
    formattedDate.push(birthday, month);
    return formattedDate

  }

  showUserBirthday = (data) => (
    data.length > 0 && data.slice(0, 5).map((item, index) => (
      <NewUserCard key={index} borderTop={1} padding="11px 17px">
        <div className="employee-info">
          <ImageContainer>
            <Image image={item.profile_image} alt="no-image" />
          </ImageContainer>
          <div className="user-birthday">
            <div className="user-name">
              <div>{item.name}</div>
              <div>{item.department}</div>
            </div>
            <div>
              <p className={'birth-date'}>{this.getFormattedBirthday(item.birthday)[0]}</p>
              <p className={'birth-date'}>{this.getFormattedBirthday(item.birthday)[1]}</p>
            </div>
          </div>
        </div>
      </NewUserCard>
    ))
  );

  userBirthdayDashboard = (userBirthday, bgColor) =>
    <BirthdayContainer>
      <div className="boxShadow">
        <HeaderContainer setColor={bgColor}>
          <HeaderTitle>
            <p>Upcoming Monthly Themes</p>
          </HeaderTitle>
        </HeaderContainer>
        <CommonContainer float={1} padding="0px">
          {isEmpty(userBirthday) ? <NoData>No Birthday</NoData> : this.showUserBirthday(userBirthday)}
          {userBirthday.length > 5 ? <ButtonContainer onClick={this.showBirthDayModal}>See All</ButtonContainer> : null}
        </CommonContainer>
      </div>
    </BirthdayContainer>
    ;

  showUpcomingThemes = (data) => {
    const slicedArray = data && data.length > 0 ? data.length >= 4 ? data.slice(0, 3) : data : [];
    return <MoreMonthData style={{marginTop:'-25px'}}>
      <CommonContainer marginTop="1px" float={1} padding="10px" bgColor={'white'}>
        {slicedArray.length > 0 ? slicedArray.map((item, index) => (
          !isNull(item) && !isUndefined(item) && !isUndefined(item.background_color) && item.background_color && item.background_color.length > 0 ? <ThemeCard key={index} padding="15px 7px" bgColor={item.background_color} borderTop={1} justify={1}>
            <ThemeGrid gridWidth="auto">
              {item.theme_icon && item.theme_icon.length > 0 ? <LazyImage src={`${ImageUrl}/${item.theme_icon}`} width={index === 0 ? '50px' : '50px'} /> : ''}
            </ThemeGrid>
            <DataThemeGridNew groupActivity={0}>
              <div className="new_theme" style={{ paddingLeft: "15px" }}>
                {`${item.month} ${item.year}`}
              </div>
              <DataThemeGrid>
                <div className="theme">{item.theme_text}</div>
              </DataThemeGrid>
            </DataThemeGridNew>
          </ThemeCard> : ""
        )) : null
        }
      </CommonContainer>
      <SeeAll onClick={this.addMonthlyPopUp} style={{color:"#005c87",background:"#f2f7f9"}}>See All</SeeAll>
    </MoreMonthData>
  };


  showNewUpcomingThemes = (bgColor, data) => (
    (data && data.length > 0 ?
      <BirthdayContainerNew newMargin={'newMargin'} style={{paddingTop:"0px"}}>
        <div className="boxShadow" style={{ backgroundColor: "white",borderRadius:'6px 6px 0px 0px' }} >
          {this.renderHeader("Upcoming Monthly Themes", bgColor, false,"#005c87")}
          {this.showUpcomingThemes(data)}
        </div>
      </BirthdayContainerNew> : '')
  );

  RealChallengeData = (color, data) => (
    (data ?
      <CardDashboard style={{marginBottom:'25px'}}>
        <div >
          {this.challengesData(data, color)}
        </div>
      </CardDashboard> : '')
  );

  topEventData = (color, data) => (
    (data ?
      <CardDashboard style={{marginBottom:'15px'}}>
        <div>
          {this.eventData(data, color)}
        </div>
      </CardDashboard> : '')
  );
  render() {
    const { wellnessInitiatives, topChallenges, newUsers, eligibleEmployee, /*userBirthday,*/ themesHeader, topEvents, UpcomingThemes, history } = this.props;
    const { /*showBirthdayModal,*/ showRolesPopup, companyPopup, singleCompanyPopUpState, thankYouPopupState, featureNewData, value, showAllInitiatives,showDetailPopup, detailInitiative } = this.state;
    const d = new Date;
    const currentMonthIndex = d.getMonth();
    const themeStyle = themesHeader.filter((item, index) => index == currentMonthIndex);
    if (currentMonthIndex === 9 || currentMonthIndex === 10 || currentMonthIndex === 11) {
      themeStyle.push(UpcomingThemes[0])
      themeStyle.push(UpcomingThemes[1])
      themeStyle.push(UpcomingThemes[2])
    }
    if ((isNull(topChallenges) || isUndefined(topChallenges) || isNull(topEvents) || isUndefined(topEvents) || isUndefined(newUsers) || isNull(newUsers)
      || isUndefined(wellnessInitiatives) || isNull(wellnessInitiatives))) {
      return <Waiting />;
    }
    return (
      <EngagementTab style={{width:"1246px",margin:"auto",float:"none"}}>
        <CardDashboardNew>
          {this.showEngagementEmployeeDetail(eligibleEmployee)}
        </CardDashboardNew>
        <EngagementContainer>
          <div style={{ "width": "34%" }}>
            {this.props.role =="ADMIN" && <div style={{width:"398px",height:"160px",display:'flex',flexDirection:'column',gap:'15px',padding:"15px",background:"white",borderRadius:"6px",marginBottom:"20px"}}>
              <div style={{display:'flex',gap:'12px'}}>
                <div style={{width:'130px',height:'130px',background:"#f2f7f9",borderRadius:"6px",display:'flex',alignItems:"center",justifyContent:"center"}}> 
                  <img src={ImageUrl+"/"+this.props.companyInfoData?.companyInfo?.[0]?.header_logo} alt="No Company Image" style={{width:"110px",height:"auto"}} />
                </div>
                <div style={{width:"60%"}}>
                  <p style={{color:"#669db7",fontFamily:'rubik',fontSize:"16px",lineHeight:"24px",marginBottom:'2px'}}>Company Name:</p>
                  <p style={{color:"#005c87",fontFamily:'rubik-medium',fontSize:"18px"}}>{this.props.companyInfoData?.companyInfo?.[0]?.company_name}</p>
                  <button style={{width:"100%",height:"40px",border:'1px solid #005c87',borderRadius:"3px",fontSize:"18px",fontFamily:'rubik-medium',display:'flex',justifyContent:"center",alignItems:"center",color:"#005c87",background:"white",marginTop:this.props.companyInfoData?.companyInfo?.[0]?.company_name.length > 24 ?"0px":'25px'}} onClick={()=>{this.props.showCompanyInfoPopup(this.props.companyId)}} >Update Branding</button>
                </div>
              </div>
            </div>}

            {this.props.companyBranding?.company_branding?.show_challenge === 1 ?<div>

              {topChallenges.length === 0 ? <ConnectDevice margin={"1"} style={{marginBottom:'15px'}}>
                <HeaderTitle newColor={'newColor'}>Company Challenges</HeaderTitle>
                <div style={{width:"399px",height:"216px",background:"white",borderRadius:"6px",display:'flex',gap:'20px',flexDirection:'column',justifyContent:"center",alignItems:"center"}}>
                  <div style={{display:'flex',gap:"31px", justifyContentL:"center",alignItems:"center"}}>
                    <img src={ImageUrl + "/images/AdminEngagement/adminDashboardNoEventsChallenges.svg"} alt="alternative" style={{width:"108.50px",height:'90px'}}/>
                    <p style={{fontFamily:'Rubik',color:"#005c87",fontSize:"16px"}}>No Active Challenges in here <br/> for your company</p>
                  </div>
                  <button style={{width:"320px",height:'50px',display:'flex',alignItems:'center',justifyContent:"center",border:"none",borderRadius:'6px',color:"white",background:"#005c87",fontFamily:'rubik-medium',fontSize:"18px",boxShadow: "0px 8px 25px 0px #005C8766"}} onClick={() => this.props.history.push(`/company/challenges/categories`)}>Launch Challenge</button>
                </div>
              </ConnectDevice> : this.RealChallengeData('none', topChallenges)}
            </div>:null}
            {this.props.companyBranding?.company_branding?.show_events === 1 ?<div>
              {topEvents.length === 0 ? <NewConnectDevice style={{marginBottom:"20px"}}>

                <HeaderTitle newColor={'newColor'} style={{ "padding-top": "10px",color:"#005c87" }}>Company Events</HeaderTitle>
                <div style={{width:"399px",height:"216px",background:"white",borderRadius:"6px",display:'flex',gap:'20px',flexDirection:'column',justifyContent:"center",alignItems:"center"}}>
                  <div style={{display:'flex',gap:"31px", justifyContentL:"center",alignItems:"center"}}>
                    <img src={ImageUrl + "/images/AdminEngagement/adminDashboardNoEventsChallenges.svg"} alt="alternative" style={{width:"108.50px",height:'90px'}}/>
                    <p style={{fontFamily:'Rubik',color:"#005c87",fontSize:"16px"}}>No Live Events in here <br/> for your company</p>
                  </div>
                  <button style={{width:"320px",height:'50px',display:'flex',alignItems:'center',justifyContent:"center",border:"none",borderRadius:'6px',color:"white",background:"#005c87",fontFamily:'rubik-medium',fontSize:"18px",boxShadow: "0px 8px 25px 0px #005C8766"}} onClick={() => this.props.history.push(`/company/events/create`)}>Create Event</button>
                </div>
              </NewConnectDevice> : this.topEventData('none', topEvents)}
            </div>:null}
            {showRolesPopup && <MonthPopUp showModalPopUp={this.addMonthlyPopUp} showModal={showRolesPopup} onClose={this.userModal} />}
            <div>
              {this.showNewUpcomingThemes(false, UpcomingThemes)}
            </div>
          </div>
          <div style={{ "width": "66%" }}>
            <div style={{ "display": "flex", "margin-bottom": "12.5px", "justifyContent": "space-between" }}>
              <CardDashboardN>
                {this.newUsersDashboard(newUsers,false,'#005c87')}
              </CardDashboardN>
              {this.props.companyBranding?.company_branding?.show_biometric ? <SplitEngagementSection style={{ "maxWidth": "399px" }}>
                {this.showNewAddInitiatives(wellnessInitiatives,false,'#005c87')}
                {companyPopup && <CompanyInitiative companyPopup={this.singleCompanyPopUp} showModal={companyPopup} onClose={this.companyModal} />}
                {singleCompanyPopUpState && <SingleCompanyInitiative selectedInitiative={this.state.selectedInitiative} singleCompanyPopUp={this.thankYouPopup} showModal={singleCompanyPopUpState} onClose={this.singleCompanyModal} featureNewData={featureNewData} onChangeDropdown={this.onChangeDropdown} value={value} handleArrow={this.handleArrow} arrowStatus={this.state.pointsArrow} step={this.state.step} launchDate={new Date(this.state.launchDate)} status={this.state.isUpcoming} onNext={this.onNext} onBack={this.onBack} toggleRadioButton={this.toggleRadioButton} selectedOption={this.state.selectedOption} onChange={this.onChange} calendarStatus={this.state.calendarStatus} toggleCalendar = {this.toggleCalendar} isEdit={this.state.isEdit} />}
                {thankYouPopupState && <ThankYouPopup selectedInitiative={this.state.selectedInitiative} thankYouPopup={this.thankYouPopup} showModal={thankYouPopupState} value={value} history={history} onClose={this.thankYouCloseModal} />}
                {this.state.isDeletePopup && <DeletePopup showModal={this.state.isDeletePopup} selectedInitiative={this.state.selectedInitiative} history={this.props.history} companyId={this.props.companyId} onClose={this.closeDeletePopup} successFunc={this.successFunc}/> }
              </SplitEngagementSection>:null}
            </div>
            {this.props.companyBranding?.company_branding?.show_education ?<div style={{ "display": "flex", "justifyContent": "space-between" }}>
              {this.showCardData()}
            </div>:null}
            {this.props.companyBranding?.company_branding?.show_education ?<div style={{ "display": "flex", "justifyContent": "space-between" }}>
              {this.showCardDataNew()}
            </div>:null}
            {this.props.companyBranding?.company_branding?.show_education ? <div style={{ "display": "flex", "justifyContent": "space-between" }}>
              {this.showCardSixData()}
            </div>:null}
          </div>
        </EngagementContainer>
        <CardDashboard>
          <NewCardDashboard />
        </CardDashboard>
        {/* <BirthdayModal
          showModal={showBirthdayModal}
          closeModal={this.hideDeviceModal}
          listOfBirthday={userBirthday}
        /> */}
        {showAllInitiatives && <ShowAllCompanyInitiative showModal={showAllInitiatives} onClose={this.showAllInitiatives} wellnessInitiatives={wellnessInitiatives}/>}
        {this.state.showCompanyInfoPopup &&<CompanyInfoUpdate show={this.state.showCompanyInfoPopup} hidePopup={this.props.hideCompanyInfoPopup} updateInformationApi={this.updateInformationApi} companyId={this.props.companyId} companyName={this.props.companyDetailsInfo && this.props.companyDetailsInfo.company_name} companyLogo={this.props.companyDetailsInfo && this.props.companyDetailsInfo.header_logo} companyColor={this.props.colorInfo && this.props.colorInfo.color_branding && this.props.colorInfo?.color_branding[1]?.color_code} companyInfoData={this.props?.companyInfoData}/>}
        {showDetailPopup && <DetailPopup showModal={showDetailPopup} detailInitiative={detailInitiative} onClose={this.closeDetailPopup} companyId={this.props.companyId} history={history}/>}
      </EngagementTab>
    );
  }
}

EngagementSectionV2.propTypes = {
  employeeCount: PropTypes.number,
  companyId: PropTypes.number,
  wellnessInitiatives: PropTypes.array,
  topChallenges: PropTypes.array,
  topEvents: PropTypes.array,
  companyBlockData: PropTypes.object,
  newUsers: PropTypes.array,
  eligibleEmployee: PropTypes.array,
  challengesWellnessCountKey: PropTypes.array,
  companyEducationDetailsKey: PropTypes.array,
  // userBirthday: PropTypes.array,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  themesHeader: PropTypes.array,
  companyInitiatives: PropTypes.array,
  UpcomingThemes: PropTypes.array,
  addCompanyInitiatives: PropTypes.func,
  companyBrandingCallBack: PropTypes.func,
  updateCompanyInfo: PropTypes.func,
  companyDetailsInfo: PropTypes.object,
  colorInfo: PropTypes.object,
  fetchCompanyInfo: PropTypes.func,
  companyInfoData: PropTypes.object,
  showCompanyInfoPopup: PropTypes.object ,
  hideCompanyInfoPopup: PropTypes.object,
  role:PropTypes.any,
  getCompanyInitiatives:PropTypes.func,
  getTopWellnessInitiatives:PropTypes.func,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  // userBirthday: state.companyDashboard.userBirthday,
  employeeCount: state.companyDashboard.employeeCount,
  themesHeader: state.profileData.themesHeaderAll,
  companyInitiatives: state.companyDashboard.companyInitiatives,
  UpcomingThemes: state.profileData.UpcomingThemes,
  eligibleEmployee: state.companyDashboard.eligibleEmployee,
  challengesWellnessCountKey: state.companyDashboard.challengesWellnessCountKey,
  companyEducationDetailsKey: state.companyDashboard.companyEducationDetailsKey,
  companyDetailsInfo:state.profileData.companyDetails,
  colorInfo: state.wellnessDashboard.companyBrandingDetails,
  companyInfoData: state.companyDashboard.companyInfoData,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  addCompanyInitiatives: (data, companyId, year) => dispatch(addCompanyInitiatives(data, companyId, year)),
  fetchCompanyInfo: (id) => dispatch(fetchCompanyInfo(id)),
  updateCompanyInfo: (obj) => dispatch(updateCompanyInfo(obj)),
  getCompanyInitiatives: (companyId) => dispatch(getCompanyInitiatives(companyId)),
  getTopWellnessInitiatives : (companyId,year) => dispatch(getTopWellnessInitiatives(companyId,year)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EngagementSectionV2);